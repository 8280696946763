
import { defineComponent, ref } from 'vue';
import { ElMessageBox, ElMessage } from 'element-plus';
import { useSegpays, useDeleteSegpay } from '@/composables/api';
import { SegpayIdOptions } from '@/services/api';
import { Delete as DeleteIcon } from '@element-plus/icons';
import DateRangePicker from '@/components/date-range-picker/Index.vue';
import dayjs from 'dayjs';

export default defineComponent({
  components: {
    DateRangePicker
  },
  setup() {
    const page = ref(1);
    const date = ref([]);
    const dateRange = ref([]);
    const { data, isLoading, isFetching, refetch } = useSegpays({ page, date });
    const { isLoading: isDeletedLoading, mutate } = useDeleteSegpay();
    const statusIndex = ref(undefined);

    const setDateRange = (_date: any) => {
      date.value = _date;
    };

    const deleteSegpay = ({ id }: SegpayIdOptions, index: Number) => {
      statusIndex.value = index;
      ElMessageBox.confirm('Are you sure you want to delete?', 'Warning', {
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        type: 'warning'
      })
        .then(() => {
          mutate(
            { id },
            {
              onSuccess() {
                refetch.value();

                ElMessage({
                  type: 'success',
                  message: 'Delete successfully'
                });
              },
              onError(error: any) {
                ElMessage({
                  message: error.response?.data.message,
                  type: 'error'
                });
              }
            }
          );
        })
        .catch();
    };

    return {
      page,
      data,
      isLoading,
      isFetching,
      isDeletedLoading,
      deleteSegpay,
      statusIndex,
      date,
      dateRange,
      setDateRange
    };
  }
});
